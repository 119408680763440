//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { fetchMenu } from '@/api/master'

export default {
  name: 'TheSidebar',
	data() {
		return {
			nav: [
				{
					_name: 'CSidebarNav',
					_children: []
				}
			]
		}
	},
  computed: {
    show () {
      return this.$store.state.sidebarShow
    },
    minimize () {
      return this.$store.state.sidebarMinimize
    },
		menu () {
			return this.$store.state.sidebarMenu
		}
  },
	async mounted() {
		await new Promise((resolve, reject) => {
			if (this.$store.state.sidebarMenu.length === 0) {
				fetchMenu().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.nav = [
							{
								_name: 'CSidebarNav',
								_children: data
							}
						]
						this.$store.commit('set', ['sidebarMenu', this.nav])
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					reject(error)
				})
			}
		})
	}
}
