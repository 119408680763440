//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheSidebar from './TheSidebar'
import TheHeader from './TheHeader'
// import TheFooter from './TheFooter'

export default {
	name: 'TheContainer',
	components: {
		TheSidebar,
		TheHeader
		// TheFooter
	},
	computed: {
		show() {
			return this.$store.state.loading
		}
	},
}
