//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import TheHeaderDropdownNoti from './TheHeaderDropdownNoti'
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import moment from 'moment'
import {fetchSyncDataLatest, syncData} from "@/api/admin";
import {Message} from "element-ui";

export default {
	name: 'TheHeader',
	components: {
		TheHeaderDropdownNoti,
		TheHeaderDropdownAccnt
	},
	data() {
		return {
			lastSync: '20220721 15:19:30',
			role: '',
			sync: 'none'
		}
	},
	async mounted() {
		this.$session.start();
		this.role = this.$session.get('role')
		this.$store.state.loading = true
		try {
			await new Promise((resolve, reject) => {
				fetchSyncDataLatest().then(response => {
					const check = response.header
					const data = response.body
					if (check.error === 'N') {
						this.lastSync = data.sync_date
						resolve()
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
					reject(error)
				})
			})
		} finally {
			this.$store.state.loading = false
		}
	},
	methods: {
		async syncData() {
			if (this.sync === 'none') {
				this.sync = 'sync'
				let status = false;

				await syncData().then(response => {
					const check = response.header
					const data = response.body
					this.lastSync = data.sync_date
					if (check.error === 'N') {
						status = true;
					}
				}).catch(error => {
					this.errorMsg = error
					console.log(error)
				})
				if (status) {
					this.$alert('ซิงค์ข้อมูลเรียบร้อย', 'สำเร็จ', {
						confirmButtonText: 'OK',
						type: 'success'
					});
					const now = moment().format('YYYYMMDD HH:mm:ss')
					this.lastSync = now
				} else {
					this.$alert('ซิงค์ข้อมูลไม่เรียบร้อย', 'พบข้อผิดพลาด', {
						confirmButtonText: 'OK',
						type: 'error'
					});
				}
				this.sync = 'none'
			}
		},
		timeout(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}
	}
}
