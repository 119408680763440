//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
			fullname: '',
			username: '',
			role: '',
      itemsCount: 42
    }
  },
	methods: {
		logout() {
			this.$store.state.loading = true
			this.$session.start()
			this.$session.clear()
			this.$session.destroy()
			this.$store.commit('set', ['sidebarMenu', []])
			if (this.role === 0) {
				this.$router.replace('/user/login')
			} else
				this.$router.replace('/login')
		}
	},
	mounted() {
		this.$session.start()
		this.username = this.$session.get('username')
		this.fullname = this.$session.get('fullname')
		this.role = this.$session.get('role')
	}
}
